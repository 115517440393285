// import Link from 'next/link';
// import { useRouter } from 'next/router';
// import { useAuth } from '../contexts/AuthContext';
// import Image from 'next/image';

// export default function Navbar() {
//   const { isLoggedIn, logout } = useAuth();
//   const router = useRouter();

//   const handleLogout = () => {
//     logout();
//     router.push('/');
//   };

//   return (
//     <nav className="bg-gray-800 p-4 sticky top-0 z-10">
//       <div className="container mx-auto flex justify-between items-center">
//         <Link href="/">
//           <Image
//             src={'/Sojot-logo.png'}
//             height={32}
//             width={32}
//           />
//           <a className="text-white text-lg font-bold">Social Jot AI</a>
//         </Link>
//         <ul className="flex space-x-2">
//           {isLoggedIn ? (
//             <>
//               <li>
//                 <Link href="/world">
//                   <span className='text-md'>
//                     🌐
//                   </span>
//                 </Link>
//               </li>
//               <li>
//                 <Link href="/journals">
//                   <span className="text-white text-md">My Jot</span>
//                 </Link>
//               </li>
//               <li>
//                 <Link href="/profile">
//                   <span className="text-white text-md">Profile</span>
//                 </Link>
//               </li>
//               <li>
//                 <button onClick={handleLogout} className="text-white">Logout</button>
//               </li>
//             </>
//           ) : (
//             <>
//               <li>
//                 <Link href="/auth/register">
//                   <span className="text-white">Register</span>
//                 </Link>
//               </li>
//               <li>
//                 <Link href="/auth/login">
//                   <span className="text-white">Login</span>
//                 </Link>
//               </li>
//             </>
//           )}
//         </ul>
//       </div>
//     </nav>
//   );
// }

// import Link from 'next/link';
// import { useRouter } from 'next/router';
// import { useAuth } from '../contexts/AuthContext';
// import Image from 'next/image';

// export default function Navbar() {
//   const { isLoggedIn, logout } = useAuth();
//   const router = useRouter();

//   const handleLogout = () => {
//     logout();
//     router.push('/');
//   };

//   return (
//     <nav className="bg-gray-800 p-4 sticky top-0 z-10">
//       <div className="container mx-auto flex justify-between items-center">
//         <Link href="/">
//           <div className="flex items-center space-x-2">
//             <Image
//               src={'/Sojot-logo.png'}
//               height={32}
//               width={32}
//               alt="Social Jot AI Logo"
//             />
//             <span className="text-white text-lg font-bold">Social Jot</span>
//           </div>
//         </Link>
//         <ul className="flex space-x-2">
//           {isLoggedIn ? (
//             <>
//               <li>
//                 <Link href="/world">
//                   <span className='text-md'>
//                     🌐
//                   </span>
//                 </Link>
//               </li>
//               <li>
//                 <Link href="/journals">
//                   <span className="text-white text-md">My Jot</span>
//                 </Link>
//               </li>
//               <li>
//                 <Link href="/profile">
//                   <span className="text-white text-md">Profile</span>
//                 </Link>
//               </li>
//               <li>
//                 <button onClick={handleLogout} className="text-white">Logout</button>
//               </li>
//             </>
//           ) : (
//             <>
//               <li>
//                 <Link href="/auth/register">
//                   <span className="text-white">Register</span>
//                 </Link>
//               </li>
//               <li>
//                 <Link href="/auth/login">
//                   <span className="text-white">Login</span>
//                 </Link>
//               </li>
//             </>
//           )}
//         </ul>
//       </div>
//     </nav>
//   );
// }


import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAuth } from '../contexts/AuthContext';
import Image from 'next/image';

export default function Navbar() {
  const { isLoggedIn, logout } = useAuth();
  const router = useRouter();
  const currentPath = router.pathname;

  const handleLogout = () => {
    logout();
    router.push('/');
  };

  return (
    <nav className="bg-gray-800 p-4 sticky top-0 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <Link href="/">
          <div className="flex items-center space-x-2 cursor-pointer">
            <Image
              src={'/Sojot-logo.png'}
              height={32}
              width={32}
              alt="Social Jot AI Logo"
            />
            <span className="text-white text-lg font-bold">Social Jot</span>
          </div>
        </Link>
        <ul className="flex space-x-4">
          {isLoggedIn ? (
            <>
              <li>
                <Link href="/world">
                  <span className={`text-md ${currentPath === '/world' ? 'underline underline-offset-8 text-blue-300' : 'text-white'}`}>
                    🌐
                  </span>
                </Link>
              </li>
              <li>
                <Link href="/journals">
                  <span className={`text-md ${currentPath === '/journals' ? 'underline underline-offset-8 text-blue-300' : 'text-white'}`}>
                    My Jot
                  </span>
                </Link>
              </li>
              <li>
                <Link href="/profile">
                  <span className={`text-md ${currentPath === '/profile' ? 'underline underline-offset-8 text-blue-300' : 'text-white'}`}>
                    Profile
                  </span>
                </Link>
              </li>
              <li>
                <button onClick={handleLogout} className="text-white">Logout</button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link href="/auth/register">
                  <span className={`text-md ${currentPath === '/auth/register' ? 'underline underline-offset-8 text-blue-300' : 'text-white'}`}>
                    Register
                  </span>
                </Link>
              </li>
              <li>
                <Link href="/auth/login">
                  <span className={`text-md ${currentPath === '/auth/login' ? 'underline underline-offset-8 text-blue-300' : 'text-white'}`}>
                    Login
                  </span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}
