// import { useState, useContext } from 'react';
// import FeedbackModal from './FeedbackModal';
// import { useAuth } from '../contexts/AuthContext';
// const { isLoggedIn, logout } = useAuth();

// export default function Footer() {

//   // const { user } = useContext(useAuth);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleFeedbackClick = () => {
//     setIsModalOpen(true);
//   };

//   return (
//     <footer className="bg-gray-800 text-white p-4">
//       <p className="text-center text-xs">
//         © 2024 Sojot AI. All rights reserved. 
//         <button onClick={handleFeedbackClick}>We want to hear your feedback!!!</button>
//       </p>
//       <FeedbackModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
//     </footer>
//   );
// }
import { useState } from 'react';
import Link from 'next/link';
// import FeedbackModal from './FeedbackModal';
// import { useAuth } from '../contexts/AuthContext';

export default function Footer() {
  // const { isLoggedIn } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFeedbackClick = () => {
    setIsModalOpen(true);
  };

  return (
    <footer className="bg-gray-800 text-white p-4">
      <p className="text-center text-xs">
        Social Jot © 2024 Sojot. All rights reserved.{' '}
        <Link href="https://forms.gle/NhXYLmtszr7GBpfbA" target='_blank' className='text-blue-300'>
          We want to hear your feedback!
        </Link>
      </p>
      {/* <FeedbackModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isLoggedIn={isLoggedIn} /> */}
    </footer>
  );
}
